.App {
    border:0px solid red;
    position: absolute;
    width:100%;
    height:100%;
    top:0;
}

.layer {
    background-color: transparent;
    background-size: 200%;
    background-position: center;
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    -webkit-filter: blur(50px) saturate(50%); /* Safari */
    filter: blur(50px) saturate(50%);
    mix-blend-mode: lighten;
    opacity:0.0;
    animation-name: upanddown;
    animation-duration: 30s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    -webkit-animation-name: upanddown;
    -webkit-animation-duration: 30s;
    -webkit-animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
}

.layer_off {
    background-color: transparent;
    background-size: 200%;
    background-position: center;
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    -webkit-filter: blur(50px) saturate(50%); /* Safari */
    filter: blur(50px) saturate(50%);
    mix-blend-mode: lighten;
    opacity:0.0;
}

.splash_container {
    position:absolute;
    margin:auto;
    z-index:2000;
    width:100%;
    height:100%;
    background-color: transparent;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    color: #ccc;
    will-change: transform;
}

.splash_button {
    background-color: #ccc;
    border: none;
    color: black;
    padding: 15px 32px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    z-index:2100;
    width:66%;
    will-change: transform;
}

.sbBig {
    background-color: transparent;
    border: none;
    color: #ccc;
    padding: 25px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 32px;
    z-index:2100;
    filter: drop-shadow(0 0px 16px #ccc);
}

.sbVeryBig {
    background-color: transparent;
    border: none;
    color: #ccc;
    padding: 15px 32px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 48px;
    z-index:2100;
    filter: drop-shadow(0 0px 16px #ccc);
}

.statsbar {
  width:40vh;
  margin-bottom: 5vh;
}

.statspct {
  height:4vh;
  background-color:#222a;
  text-align:left;
  overflow:visible;
  white-space: nowrap;
  padding:0px 4px;
}

.statsvol {
  height:4vh;
  background-color:#444a;
  text-align:left;
  overflow:visible;
  white-space: nowrap;
  padding:0px 4px;
}

.small {
    font-size: 1.8vh;
}

.bigger1 {
    font-size: 2.4vh;
    line-height: 3.6vh;
    -webkit-animation-name: pulse; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
    -webkit-animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
    animation-name: pulse;
    animation-duration: 4s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.bigger2 {
    font-size: 2.4vh;
    line-height: 3.6vh;
    -webkit-animation-name: pulse; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
    -webkit-animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
    animation-name: pulse;
    animation-duration: 3s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}


/* Safari 4.0 - 8.0 */
@-webkit-keyframes pulse {
  from {color: #444;}
  to {color: #fff;}
}

/* Standard syntax */
@keyframes pulse {
  from {color: #444;}
  to {color: #fff;}
}

@keyframes upanddown {
    from { opacity: 0.0; }
    to { opacity: 1.0; }
}

@-webkit-keyframes upanddown {
  from { opacity: 0.0; }
  to { opacity: 1.0; }
}
